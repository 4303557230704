// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
const firebaseConfig = {

  apiKey: "AIzaSyCeOd8CpxkB3L3LBCLpocx1facc2SF2Jzg",

  authDomain: "billsn-1abc8.firebaseapp.com",

  databaseURL: "https://billsn-1abc8-default-rtdb.firebaseio.com",

  projectId: "billsn-1abc8",

  storageBucket: "billsn-1abc8.appspot.com",

  messagingSenderId: "393145187808",

  appId: "1:393145187808:web:77f68ae82be21b41cabf85",

  measurementId: "G-5ZHCG2V4DF"

};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export {app}